import { useMemo } from 'react';

import { QueryClient, queryOptions, useQuery, useQueryClient } from '@tanstack/react-query';

import Cookies from 'js-cookie';
import { merge } from 'lodash';

import { DeserializedUser, getUserData } from '@api';

import { AUTH_USER_QUERY_KEY } from './keys';
import { AUTH_USER_INVALIDATION_QUERY_KEY, userQueryKeys } from './user';

export interface SessionData {
  user: DeserializedUser | null;
  isExpired: boolean;
}

export const sessionQueryOptions = () => {
  return queryOptions<SessionData>({
    queryKey: userQueryKeys.session,
    queryFn: async () => {
      try {
        const user = await getUserData();

        return {
          user,
          isExpired: false,
        };
      } catch (error) {
        return {
          user: null,
          isExpired: false,
        };
      }
    },
  });
};

export const setSessionUserQuery = (queryClient: QueryClient, user: DeserializedUser) => {
  const queryKey = sessionQueryOptions().queryKey;

  queryClient.cancelQueries({ queryKey });

  queryClient.setQueryData(sessionQueryOptions().queryKey, () => {
    return {
      user,
      isExpired: false,
    };
  });
};

// TODO: move in types file
type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export const updateSessionUserMagicIdQueryData = (
  queryClient: QueryClient,
  data:
    | DeepPartial<DeserializedUser>
    | ((oldData: DeserializedUser) => DeepPartial<DeserializedUser>),
) => {
  const queryKey = sessionQueryOptions().queryKey;

  queryClient.cancelQueries({ queryKey });

  const old = queryClient.getQueryData(queryKey);

  queryClient.setQueryData(queryKey, (oldData) => {
    if (!oldData) {
      return oldData;
    }

    const oldDataCopy = structuredClone(oldData);

    return {
      ...oldDataCopy,
      user: oldDataCopy.user
        ? {
            ...oldDataCopy.user,
            ...merge(oldDataCopy.user, typeof data === 'function' ? data(oldDataCopy.user) : data),
          }
        : null,
      isExpired: false,
    };
  });

  return {
    reset: () => {
      queryClient.setQueryData(queryKey, old);
    },
  };
};

export const clearSessionRelatedQueries = (queryClient: QueryClient) => {
  queryClient.setQueryData(sessionQueryOptions().queryKey, () => {
    return {
      user: null,
      isExpired: false,
    };
  });

  // NOTE: reset user related data manually because removeQueries doesn't trigger rerender
  queryClient.setQueriesData({ queryKey: [AUTH_USER_QUERY_KEY] }, null);
  // will trigger loading when useQuery will be rendered
  queryClient.removeQueries({ queryKey: [AUTH_USER_QUERY_KEY] });
};

export const markSessionQueryAsExpired = (queryClient: QueryClient) => {
  queryClient.setQueryData(sessionQueryOptions().queryKey, (data) => {
    if (!data) {
      return;
    }

    return {
      ...data,
      isExpired: true,
    };
  });
};

export const invalidateSessionRelatedMarkedQueries = (queryClient: QueryClient) => {
  // Invalidates user related queries
  // That will allow us to get fresh data with user related fields when user authorizes
  // Because some endpoints requires authorization key to get that data
  queryClient.invalidateQueries({
    queryKey: [AUTH_USER_INVALIDATION_QUERY_KEY],
  });
};

const placeholderData: SessionData = {
  user: null,
  isExpired: false,
};

export const useSession = ({ enabled }: { enabled?: boolean } = {}) => {
  const queryClient = useQueryClient();
  // false on backend and true on client
  const hasAuthorizedCookie = !!Cookies.get('Authorized');
  // isFetching is used because isLoading is false when placeholderData is set
  const query = useQuery({
    ...sessionQueryOptions(),
    enabled: typeof enabled === 'boolean' ? enabled : hasAuthorizedCookie,
  });

  const actions = useMemo(() => {
    return {
      clearSessionRelatedData: () => clearSessionRelatedQueries(queryClient),
      markSessionAsExpired: () => markSessionQueryAsExpired(queryClient),
      prepareSessionForLogout: () => markSessionQueryAsExpired(queryClient),
    };
  }, [queryClient]);

  const session = query.data || placeholderData;

  return {
    session,
    query,
    ...actions,
  };
};
