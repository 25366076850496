import { useMemo } from 'react';

import {
  QueryClient,
  UndefinedInitialDataInfiniteOptions,
  UndefinedInitialDataOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import Cookies from 'js-cookie';

// TODO: fix absolute imports with middleware
import {
  getAppShareLink,
  getDeveloperShareLink,
  getDiscordLoginUrl,
  getFaceLivenessSession,
  getFaceLivenessSessionResult,
  getHotOfferShareLink,
  getKYCBabt,
  getUserClaimedHotOffers,
  getUserData,
  getUserFollowedApps,
  getUserProfile,
  getUserReview,
  getUserReviews,
  GetUserReviewsSearchParams,
  getUserVotedApps,
  getXLoginUrl,
  loginByDiscord,
  loginByTelegram,
  loginByX,
  loginSendGoogleOAuthCode,
  loginSendPasswordlessEmail,
  loginTestUser,
  loginViaTonWallet,
  loginWallet,
  loginWithWallet,
  MissingFieldsEntry,
  refreshSelfHumanityScore,
  RequestSignResult,
  sendVerificationCode,
  TelegramUser,
  toggleAdsForUser,
  updateMagicIDInfo,
  updateUserAdditionalInfo,
  updateUserData,
  WalletTypeInUppercase,
} from '@shared/api';
import { connectToMagicID, deleteWallet } from '@shared/api/auth';
import {
  addWallet,
  checkMagicIDForNecessaryData,
  checkUserName,
  confirmVerificationCode,
  connectTonWallet,
  DEFAULT_GET_USER_REVIEWS_LIMIT,
  deleteMagicIDEmail,
  updateMagicIdUserAdditionalData,
} from '@shared/api/user';
import storageKeys from '@shared/constants/storageKeys';
import { ApiOptions } from '@shared/helpers/api';
import { getErrorMessage } from '@shared/helpers/error';
import useTelegramMiniAppSdk from '@shared/hooks/useTelegramMiniAppSdk';

import { Wallet } from '@tonconnect/ui-react';

import { useFeatureToggles } from '../useFeatureToggles';
import { AUTH_USER_QUERY_KEY, SESSION_QUERY_KEY } from './keys';
import { setSessionUserQuery, updateSessionUserMagicIdQueryData } from './session';

export const AUTH_USER_INVALIDATION_QUERY_KEY = 'authUserInvalidation';

const getReferralAction = (id: string, type: 'app' | 'hot-offer' | 'developer') => {
  if (type === 'app') {
    return getAppShareLink(id);
  }

  if (type === 'hot-offer') {
    return getHotOfferShareLink(id);
  }

  return getDeveloperShareLink(id);
};

export const userQueryKeys = {
  session: [SESSION_QUERY_KEY],
  // AUTH_USER_INVALIDATION_QUERY_KEY is used because reviews can have user related data,
  // like userVote, userFunnyVote and we should revalidate it when user login
  publicUser: (id: string) => [AUTH_USER_INVALIDATION_QUERY_KEY, 'publicUser', id],
  votedApps: (id: string, limit: number) => ['votedApps', id, limit],
  followedApps: (id: string, limit: number) => ['followedApps', id, limit],
  reviews: (
    userId: string,
    {
      limit = DEFAULT_GET_USER_REVIEWS_LIMIT,
      type = 'all',
      period = 'all',
    }: GetUserReviewsSearchParams = {},
  ) => [
    'userReviews',
    userId,
    {
      limit,
      type,
      period,
    },
  ],
  // AUTH_USER_INVALIDATION_QUERY_KEY is used because review can have user related data,
  // like userVote, userFunnyVote and we should revalidate it when user login
  review: (id: string, type: string) => [AUTH_USER_INVALIDATION_QUERY_KEY, 'userReview', id, type],
  humanityScore: [AUTH_USER_QUERY_KEY, 'humanityScore'],
  checkMagicIDNecessaryData: (
    fields: Parameters<typeof checkMagicIDForNecessaryData>[0]['searchParams'],
  ) => [AUTH_USER_QUERY_KEY, 'checkMagicIDNecessaryData', fields],
  referralUrl: (id: string, type: 'app' | 'hot-offer' | 'developer') => [
    AUTH_USER_QUERY_KEY,
    'referralUrl',
    id,
    type,
  ],
  faceLivenessSession: [AUTH_USER_QUERY_KEY, 'faceLivenessSession'],
  faceLivenessSessionResult: (sessionId: string) => [
    AUTH_USER_QUERY_KEY,
    'faceLivenessSession',
    sessionId,
  ],
  xLoginUrl: (from: string) => [AUTH_USER_QUERY_KEY, 'xLoginUrl', from],
  discordLoginUrl: (from: string) => [AUTH_USER_QUERY_KEY, 'discordLoginUrl', from],
};

export const prefetchPublicUserQuery = (
  clientQuery: QueryClient,
  options: ApiOptions<{ searchParams: { id: string } }>,
) => {
  return clientQuery.prefetchQuery({
    queryKey: userQueryKeys.publicUser(options.searchParams.id),
    queryFn: () => getUserProfile(options),
  });
};

export const prefetchUserVotedAppsQuery = (
  clientQuery: QueryClient,
  options: ApiOptions<{ searchParams: { id: string; limit: number; offset: number } }>,
) => {
  return clientQuery.prefetchInfiniteQuery({
    queryKey: userQueryKeys.votedApps(options.searchParams.id, options.searchParams.limit),
    queryFn: () => getUserVotedApps(options),
    initialPageParam: 0,
  });
};

export const prefetchUserFollowedAppsQuery = (
  clientQuery: QueryClient,
  params: ApiOptions<{ searchParams: { id: string; limit: number; offset: number } }>,
) => {
  return clientQuery.prefetchInfiniteQuery({
    queryKey: userQueryKeys.followedApps(params.searchParams.id, params.searchParams.limit),
    queryFn: () => getUserFollowedApps(params),
    initialPageParam: 0,
  });
};

export const prefetchUserReviewQuery = (
  clientQuery: QueryClient,
  params: ApiOptions<{
    searchParams: { id: string; type: 'suggestion' | 'review' | 'trust-review' };
  }>,
) => {
  return clientQuery.prefetchQuery({
    queryKey: userQueryKeys.review(params.searchParams.id, params.searchParams.type),
    queryFn: () => getUserReview(params),
  });
};

export const usePublicUserQuery = (options: ApiOptions<{ searchParams: { id: string } }>) => {
  return useQuery({
    queryKey: userQueryKeys.publicUser(options.searchParams.id),
    queryFn: () => getUserProfile(options),
  });
};

export const useGetKYCBabtMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => getKYCBabt(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [userQueryKeys.session] });
    },
  });
};

export const useUserFollowedAppsInfiniteQuery = ({
  limit = 20,
  id,
}: {
  id: string;
  limit?: number;
}) => {
  return useInfiniteQuery({
    queryKey: userQueryKeys.followedApps(id, limit),
    queryFn: ({ pageParam }) =>
      getUserFollowedApps({ searchParams: { id, limit, offset: pageParam } }),
    getNextPageParam: (lastPage, pages = []) => {
      return lastPage.length === limit ? limit * pages.length : undefined;
    },
    initialPageParam: 0,
  });
};

export const useUserVotedAppsInfiniteQuery = ({
  limit = 20,
  id,
}: {
  limit?: number;
  id: string;
}) => {
  return useInfiniteQuery({
    queryKey: userQueryKeys.votedApps(id, limit),
    queryFn: ({ pageParam }) =>
      getUserVotedApps({ searchParams: { id, limit, offset: pageParam } }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages = []) => {
      return lastPage.length === limit ? limit * pages.length : undefined;
    },
  });
};

export const prefetchUserReviewsQuery = (
  clientQuery: QueryClient,
  userId: string,
  params?: Parameters<typeof getUserReviews>[1],
) => {
  return clientQuery.prefetchInfiniteQuery({
    queryKey: userQueryKeys.reviews(userId, params?.searchParams),
    queryFn: () => getUserReviews(userId, params),
    initialPageParam: 0,
  });
};

export const useUserReviewsInfiniteQuery = (
  userId: string,
  params: Partial<GetUserReviewsSearchParams>,
) => {
  return useInfiniteQuery({
    queryKey: userQueryKeys.reviews(userId, params),
    queryFn: ({ pageParam }) =>
      getUserReviews(userId, {
        searchParams: {
          ...params,
          offset: pageParam,
        },
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages = []) => {
      return lastPage?.length === DEFAULT_GET_USER_REVIEWS_LIMIT
        ? DEFAULT_GET_USER_REVIEWS_LIMIT * pages.length
        : undefined;
    },
  });
};

export const useUserReview = ({
  id,
  type,
}: {
  id: string;
  type: 'suggestion' | 'review' | 'trust-review';
}) =>
  useQuery({
    queryKey: userQueryKeys.review(id, type),
    queryFn: () => getUserReview({ searchParams: { id, type } }),
  });

const createLoginMutation = <Params extends { referredBy?: string; [key: string]: any }>(
  login: (options: ApiOptions<{ json: Params }>) => Promise<any>,
) => {
  return () => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: async (params: Params) => {
        const referredBy = Cookies.get(storageKeys.REFERRAL);

        const data = await login({
          json: {
            ...params,
            referredBy,
          },
        });

        const userData = await getUserData();

        return {
          ...userData,
          isNewUser: data.newUser,
        };
      },
      onSuccess: (data) => {
        Cookies.remove(storageKeys.REFERRAL);

        setSessionUserQuery(queryClient, data);
      },
    });
  };
};

export const useLoginGoogleMutation = createLoginMutation(loginSendGoogleOAuthCode);

export const useLoginPasswordlessMutation = createLoginMutation(loginSendPasswordlessEmail);

export const useLoginWalletMutation = createLoginMutation(loginWallet);

export const useLoginTestUserMutation = createLoginMutation(loginTestUser);

export const useLoginByTelegramMutation = createLoginMutation(loginByTelegram);

export const useLoginByXMutation = createLoginMutation(loginByX);

export const useLoginByDiscordMutation = createLoginMutation(loginByDiscord);

export const useAuthMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      address,
      signature,
      network,
      message,
      walletType,
      v2,
    }: RequestSignResult) => {
      const loginResponse = await loginWithWallet({
        json: {
          pub_key: address,
          signature,
          network,
          message,
          wallet: walletType.toUpperCase() as WalletTypeInUppercase,
        },
        ...(v2
          ? {
              headers: {
                'x-sign-in': 'v2',
              },
            }
          : {}),
      });

      if (loginResponse.error) {
        throw new Error('Login failed');
      }

      const referredBy = Cookies.get(storageKeys.REFERRAL);
      const magicIdResponse = await connectToMagicID({
        json: { network, pub_key: address, wallet: walletType, referredBy },
      });

      if (magicIdResponse.error) {
        throw new Error('Connect failed');
      }

      const user = await getUserData();

      return {
        user,
        isNewUser: loginResponse.newUser,
      };
    },
    onSuccess: (data) => {
      setSessionUserQuery(queryClient, data.user);
    },
  });
};

export const useUpdateUserDataMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (avatar: string) => {
      await updateUserData({ json: { avatar } });

      return getUserData();
    },
    onSuccess: (data) => {
      setSessionUserQuery(queryClient, data);
    },
  });
};

export const useAddWalletMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (options: Parameters<typeof addWallet>[0]['json']) => {
      await addWallet({ json: options });

      return getUserData();
    },
    onSuccess: (data) => {
      setSessionUserQuery(queryClient, data);
    },
  });
};

export const useCheckMagicIDNecessaryDataQuery = (
  fields: Parameters<typeof checkMagicIDForNecessaryData>[0]['searchParams'],
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof checkMagicIDForNecessaryData>>,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >,
) => {
  return useQuery({
    queryKey: userQueryKeys.checkMagicIDNecessaryData(fields),
    queryFn: () => checkMagicIDForNecessaryData({ searchParams: fields }),
    staleTime: 0,
    gcTime: 0,
    ...options,
  });
};

export const useCheckMagicIDNecessaryDataMutation = () => {
  return useMutation({
    mutationFn: async (
      fields: Parameters<typeof checkMagicIDForNecessaryData>[0]['searchParams'],
    ) => checkMagicIDForNecessaryData({ searchParams: fields }),
  });
};

export const useReferralUrlQuery = (
  id: string,
  type: 'app' | 'hot-offer' | 'developer',
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getReferralAction>>,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >,
) => {
  return useQuery({
    queryKey: userQueryKeys.referralUrl(id, type),
    queryFn: () => getReferralAction(id, type),
    ...options,
  });
};

export const useUpdateUsernameMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (name: string) => {
      await updateMagicIDInfo({ json: { name } });

      return name;
    },
    onSuccess: (name) => {
      updateSessionUserMagicIdQueryData(queryClient, {
        attributes: {
          name,
        },
      });
    },
  });
};

export const useCheckUserNameQuery = (name: string | null) => {
  return useQuery({
    queryKey: ['checkUserName', name],
    queryFn: async () => {
      if (!name) {
        return {
          message: 'Username is required',
          isValid: false,
        };
      }

      if (!/^[a-zA-Z0-9_]{4,20}$/.test(name)) {
        return {
          message:
            'The username must contain only Latin letters, numbers and the symbol "_". It must be at least 4 characters and not more than 20',
          isValid: false,
        };
      }

      try {
        await checkUserName({ json: { name } });

        return {
          message:
            'This username is available for use. Please note, your username cannot be changed in the future.',
          isValid: true,
        };
      } catch (error) {
        return {
          message: getErrorMessage(error),
          isValid: false,
        };
      }
    },
    gcTime: 2000,
    staleTime: 1000,
    enabled: Boolean(name),
  });
};

export const useCheckUserNameWithoutRegExQuery = (name: string, options: { enabled?: boolean }) => {
  return useQuery({
    queryKey: ['checkUserNameNoRegEx', name],
    queryFn: async () => {
      try {
        await checkUserName({ json: { name } });

        return {
          isValid: true,
        };
      } catch (error) {
        return {
          message: getErrorMessage(error),
          isValid: false,
        };
      }
    },
    ...options,
  });
};

export const useUpdateDisplayNameMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (displayName: string) => {
      await updateUserAdditionalInfo({ json: { displayedName: displayName } });

      return displayName;
    },
    onSuccess: (displayedName) => {
      updateSessionUserMagicIdQueryData(queryClient, {
        attributes: {
          displayedName,
        },
      });
    },
  });
};

export const useUpdateDescriptionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (description: string) => {
      await updateUserAdditionalInfo({ json: { description } });

      return description;
    },
    onSuccess: (description) => {
      updateSessionUserMagicIdQueryData(queryClient, {
        attributes: {
          description,
        },
      });
    },
  });
};

export const useUpdateMagicIdAdditionalDataMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Parameters<typeof updateMagicIdUserAdditionalData>[0]) => {
      return updateMagicIdUserAdditionalData(data);
    },
    onSuccess: (data) => {
      updateSessionUserMagicIdQueryData(queryClient, {
        attributes: data,
      });
    },
  });
};

export const useSendVerificationCodeMutation = () => {
  return useMutation({ mutationFn: (email: string) => sendVerificationCode({ json: { email } }) });
};

export const useSendVerificationCodeQuery = (email: string, options?: { enabled?: boolean }) => {
  return useQuery({
    queryKey: [AUTH_USER_QUERY_KEY, 'sendVerificationCode', email],
    queryFn: () => sendVerificationCode({ json: { email } }),
    ...options,
  });
};

export const useConfirmVerificationCodeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (options: { email: string; code: string }) => {
      await confirmVerificationCode({ json: { ...options, code: Number(options.code) } });

      return options.email;
    },
    onSuccess: (email) => {
      updateSessionUserMagicIdQueryData(queryClient, {
        attributes: {
          email,
        },
      });
    },
  });
};

export const useDeleteEmailMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteMagicIDEmail(),
    onSuccess: () => {
      updateSessionUserMagicIdQueryData(queryClient, {
        attributes: {
          email: '',
        },
      });
    },
  });
};

export const useDeleteWalletMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: number) => {
      await deleteWallet({ id });

      return getUserData();
    },
    onSuccess: (data) => {
      setSessionUserQuery(queryClient, data);
    },
  });
};

export const useSelfHumanityScoreQuery = (
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof checkMagicIDForNecessaryData>>,
      unknown,
      {
        gitCoin: MissingFieldsEntry['description']['humanityScore'];
        faceCheckPassed: MissingFieldsEntry['description']['faceCheckPassed'];
      },
      any
    >,
    'enabled'
  >,
) => {
  const featureToggles = useFeatureToggles();
  const query = useQuery({
    queryKey: userQueryKeys.humanityScore,
    queryFn: () =>
      checkMagicIDForNecessaryData({
        searchParams: { humanityScore: 1, faceCheckPassed: 1 },
      }),
    select: (data) => {
      return {
        gitCoin: data?.description?.humanityScore,
        faceCheckPassed: data.description.faceCheckPassed,
      };
    },
    gcTime: 0,
    staleTime: 0,
    ...options,
  });

  const gitCoin = query.data?.gitCoin;
  const faceCheckPassed = query.data?.faceCheckPassed;

  const customErrors = useMemo(() => {
    let humanityScoreError = '';
    let livelinessScoreError = '';

    if (featureToggles.humanityVerification) {
      if (typeof gitCoin?.score !== 'number') {
        humanityScoreError = 'Connect your Gitcoin Passport';
      } else if (!gitCoin.acceptable) {
        humanityScoreError = `Insufficient Gitcoin Passport Score.\nRaise to ${gitCoin.threshold} to collect rewards.`;
      }
    }

    if (featureToggles.livenessVerification) {
      if (faceCheckPassed === false) {
        livelinessScoreError = 'Pass liveliness verification';
      }
    }

    // Case 1: (both is ON), [both not passed] OR [one of them not passed]
    if (featureToggles.humanityVerification && featureToggles.livenessVerification) {
      if (humanityScoreError && livelinessScoreError) {
        return { humanityScoreError: humanityScoreError + ' OR ' + livelinessScoreError };
      }

      return { humanityScoreError: '' };
    }

    // Case 2.1: only faceCheck is ON
    if (featureToggles.livenessVerification) {
      return { humanityScoreError: livelinessScoreError };
    }

    // Case 2.2:only  gitScore is ON
    return {
      humanityScoreError,
    };
  }, [featureToggles.humanityVerification, featureToggles.livenessVerification, query.data]);

  return { ...query, customErrors };
};

export const useRefreshSelfHumanityScoreMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => refreshSelfHumanityScore(),
    onSuccess: (data) => {
      queryClient.setQueryData(userQueryKeys.humanityScore, (oldData?: MissingFieldsEntry) => {
        if (!oldData) {
          return {
            description: {
              humanityScore: data,
            },
            success: true,
          };
        }

        return {
          ...oldData,
          description: {
            ...oldData?.description,
            humanityScore: {
              ...oldData?.description?.humanityScore,
              ...data,
            },
          },
        };
      });
    },
  });
};

export const useOptimisticUpdateSelfCoverMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (cover: string | null) => updateUserAdditionalInfo({ json: { cover } }),
    onMutate: async (cover) => {
      return updateSessionUserMagicIdQueryData(queryClient, {
        attributes: {
          cover,
        },
      });
    },
    onError: (error, data, context) => {
      context?.reset();
    },
  });
};

export const useFaceLivenessSessionQuery = (
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getFaceLivenessSession>>,
      unknown,
      unknown,
      any
    >,
    'enabled' | 'staleTime' | 'gcTime'
  >,
) => {
  return useQuery({
    queryKey: userQueryKeys.faceLivenessSession,
    queryFn: () => getFaceLivenessSession(),
    ...options,
  });
};

export const useFaceLivenessSessionResultQuery = (
  sessionId: string,
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getFaceLivenessSessionResult>>,
      unknown,
      unknown,
      any
    >,
    'enabled' | 'staleTime' | 'gcTime'
  >,
) => {
  return useQuery({
    queryFn: () => getFaceLivenessSessionResult({ searchParams: { sessionId } }),
    queryKey: userQueryKeys.faceLivenessSessionResult(sessionId),
    ...options,
  });
};

export const useXLoginUrlQuery = (from: string, options?: { enabled?: boolean }) => {
  return useQuery({
    queryFn: () => getXLoginUrl({ from }),
    queryKey: userQueryKeys.xLoginUrl(from),
    ...options,
  });
};

export const useDiscordLoginUrlQuery = (
  from: string,
  options?: {
    enabled?: boolean;
  },
) => {
  return useQuery({
    queryKey: userQueryKeys.discordLoginUrl(from),
    queryFn: () => getDiscordLoginUrl({ from }),
    ...options,
  });
};

export const useUserClaimedHotOffersInfiniteQuery = (
  {
    userId,
    searchParams,
  }: {
    userId: string;
    searchParams?: { limit?: number };
  },
  options?: Pick<
    UndefinedInitialDataInfiniteOptions<
      Awaited<ReturnType<typeof getUserClaimedHotOffers>>,
      unknown,
      unknown,
      any,
      { nextOffset: number }
    >,
    'enabled'
  >,
) => {
  const { limit = 10 } = searchParams || {};

  return useInfiniteQuery({
    queryKey: ['userClaimedHotOffers', userId],
    queryFn: ({ pageParam }) => {
      return getUserClaimedHotOffers(userId, {
        searchParams: {
          offset: pageParam?.nextOffset || 0,
          limit,
        },
      });
    },
    initialPageParam: { nextOffset: 0 },
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length === limit
        ? {
            nextOffset: pages.length * limit,
          }
        : undefined;
    },
    ...options,
  });
};

export const useConnectTonWalletMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (wallet: Wallet) => {
      const connectedTonWallet = await connectTonWallet({ json: wallet });
      return {
        user: await getUserData(),
        walletAddress: connectedTonWallet.address,
      };
    },
    onSuccess: (data) => {
      setSessionUserQuery(queryClient, data.user);
    },
  });
};

export const useChangeAdToggleMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (enable: boolean) => {
      await toggleAdsForUser(enable);
      return getUserData();
    },
    onSuccess: (data) => {
      setSessionUserQuery(queryClient, data);
    },
  });
};

export const useLoginViaTonWalletMutation = () => {
  const queryClient = useQueryClient();
  const { webApp } = useTelegramMiniAppSdk();

  return useMutation({
    mutationFn: async (wallet: Parameters<typeof loginViaTonWallet>[0]['json']) => {
      const referredBy = Cookies.get(storageKeys.REFERRAL);

      // 1. Login via TON
      const response = await loginViaTonWallet({
        json: { ...wallet, referredBy },
      });

      Cookies.remove(storageKeys.REFERRAL);

      // 2. Link Telegram account
      const user = await getUserData();

      const hasLinkedTelegram = !!user?.socials.find((social) => social.type === 'TELEGRAM');

      const userData = webApp?.initDataUnsafe.user as TelegramUser;

      if (!hasLinkedTelegram && userData) {
        // TODO: Uncomment after fix verifying on BE
        // await connectTelegram({ json: { userData } });
      }

      return {
        user,
        isNewUser: response.newUser,
      };
    },
    onSuccess: (data) => {
      setSessionUserQuery(queryClient, data.user);
    },
  });
};
