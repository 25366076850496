import { useCallback } from 'react';
import { toast } from 'react-toastify';

import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions';

import { Options } from 'ky';

import {
  addAppInCurrentUserSpace,
  getCurrentUserAppIdListInSpace,
  getCurrentUserOpenableAppListInSpace,
  OpenableApp,
  removeCurrentUserAppFromSpace,
} from '@api';

import { AUTH_USER_QUERY_KEY } from './keys';

const spaceQueryKeys = {
  currentUserAppList: [AUTH_USER_QUERY_KEY, 'currentUserAppListInSpace'],
  currentUserAppIdList: [AUTH_USER_QUERY_KEY, 'currentUserAppIdListInSpace'],
};

export const useCurrentUserOpenableAppListInSpaceQuery = (
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getCurrentUserOpenableAppListInSpace>>,
      unknown,
      unknown,
      any
    >,
    'enabled' | 'initialData' | 'placeholderData'
  >,
) => {
  return useQuery({
    queryKey: spaceQueryKeys.currentUserAppList,
    queryFn: () => getCurrentUserOpenableAppListInSpace(),
    ...options,
  });
};

export const prefetchCurrentUserOpenableAppListInSpace = (
  clientQuery: QueryClient,
  options: Options,
) => {
  return clientQuery.prefetchQuery({
    queryKey: spaceQueryKeys.currentUserAppList,
    queryFn: () => getCurrentUserOpenableAppListInSpace(options),
  });
};

export const useCurrentUserAppIdListInSpace = () => {
  return useQuery({
    queryKey: spaceQueryKeys.currentUserAppIdList,
    queryFn: () => getCurrentUserAppIdListInSpace(),
  });
};

export const prefetchCurrentUserAppIdListInSpace = (clientQuery: QueryClient, options: Options) => {
  return clientQuery.prefetchQuery({
    queryKey: spaceQueryKeys.currentUserAppIdList,
    queryFn: () => getCurrentUserAppIdListInSpace(options),
  });
};

export const removeAppInCurrentUserSpaceQueryData = (queryClient: QueryClient, appId: number) => {
  queryClient.setQueryData(spaceQueryKeys.currentUserAppList, (old?: OpenableApp[]) => {
    if (!old) {
      return old;
    }

    return old.filter((appInSpace) => appInSpace.id !== appId);
  });

  return queryClient.getQueryData<OpenableApp[]>(spaceQueryKeys.currentUserAppList);
};

export const useRemoveAppInCurrentUserSpaceMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (appId: number) => {
      return removeCurrentUserAppFromSpace({
        searchParams: {
          app_id: appId,
        },
      });
    },
    onMutate: async (appId: number) => {
      await Promise.all([
        queryClient.cancelQueries({ queryKey: spaceQueryKeys.currentUserAppList }),
      ]);
      const previousCurrentUserAppList = queryClient.getQueryData(
        spaceQueryKeys.currentUserAppList,
      );

      // only update cache if we have data
      // to make data load when user will open spaces page
      if (previousCurrentUserAppList) {
        removeAppInCurrentUserSpaceQueryData(queryClient, appId);
      }

      return {
        previousCurrentUserAppList,
      };
    },
    onError: (error, variables, previousValue) => {
      queryClient.setQueryData(
        spaceQueryKeys.currentUserAppList,
        previousValue?.previousCurrentUserAppList,
      );
    },
  });
};

export const addAppInCurrentUserSpaceQueryData = (queryClient: QueryClient, app: OpenableApp) => {
  queryClient.setQueryData(spaceQueryKeys.currentUserAppList, (old?: OpenableApp[]) => {
    if (!old) {
      return [app];
    }

    return [app, ...old];
  });

  return queryClient.getQueryData<OpenableApp[]>(spaceQueryKeys.currentUserAppList);
};

export const setAppInCurrentUserSpaceQueryData = (
  queryClient: QueryClient,
  appList?: OpenableApp[],
) => {
  queryClient.setQueryData(spaceQueryKeys.currentUserAppList, appList);
};

export const useAddAppInCurrentUserSpaceMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (app: OpenableApp) => {
      return addAppInCurrentUserSpace({ json: { app_id: app.id } });
    },
    onMutate: async (app) => {
      await Promise.all([
        queryClient.cancelQueries({ queryKey: spaceQueryKeys.currentUserAppList }),
      ]);
      const previousCurrentUserAppList = queryClient.getQueryData(
        spaceQueryKeys.currentUserAppList,
      );

      // only update cache if we have data
      // to make data load when user will open spaces page
      if (previousCurrentUserAppList) {
        addAppInCurrentUserSpaceQueryData(queryClient, app);
      }

      return {
        previousCurrentUserAppList,
      };
    },
    onSuccess: (data) => {
      if ('message' in data) {
        toast.info(data.message);
      }
    },
    onError: (error, variables, previousValue) => {
      queryClient.setQueryData(
        spaceQueryKeys.currentUserAppList,
        previousValue?.previousCurrentUserAppList,
      );
    },
  });
};

export const useToggleAppInCurrentUserSpace = () => {
  const { mutateAsync: addApp } = useAddAppInCurrentUserSpaceMutation();
  const { mutateAsync: removeApp } = useRemoveAppInCurrentUserSpaceMutation();

  return useCallback(
    ({ app, inSpace }: { app: OpenableApp; inSpace: boolean }) => {
      if (inSpace) {
        return addApp(app);
      }

      return removeApp(app.id);
    },
    [addApp, removeApp],
  );
};
