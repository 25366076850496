import {
  QueryClient,
  UndefinedInitialDataInfiniteOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions';

import { Options } from 'ky';

import {
  CAMPAIGN_LEADERBOARD_DEFAULT_PAGE_LIMIT,
  getAutoLayerCampaignParticipants,
  getCampaignLeaderboard,
  getSelfAutoLayerCampaignParticipant,
  getSelfCampaignLeaderboardMe,
  getStormTradeCampaignParticipants,
  getStory,
  getZealyAccount,
  getZealyCampaign,
  getZealyParticipants,
  Story,
  verifyZealy,
  ZealyAccount,
} from '@api';

import { DEFAULT_PARTICIPANT_LIST_LIMIT, getStormTradeSelfPosition } from '../../api';
import { AUTH_USER_QUERY_KEY } from './keys';

const storyQueryKeys = {
  zealyParticipantInfiniteList: ['zealyParticipantInfiniteList'],
  zealyAllQuests: ['zealyAllQuests'],
  selfZealyAccount: [AUTH_USER_QUERY_KEY, 'selfZealyAccount'],
  // storm trade
  stormTradeParticipantInfiniteList: ['stormTradeParticipantInfiniteList'],
  selfStormTradeParticipant: [AUTH_USER_QUERY_KEY, 'selfStormTradeParticipant'],
  // auto layer
  selfAutoLayerParticipant: [AUTH_USER_QUERY_KEY, 'selfAutoLayerParticipant'],
  autoLayerParticipantInfiniteList: ['autoLayerParticipantInfiniteList'],
  campaignLeaderboard: (campaignSlug: string) => ['campaignLeaderboard', campaignSlug],
  selfCampaignLeaderboardMe: (campaignSlug: string) => [
    AUTH_USER_QUERY_KEY,
    'campaignLeaderboard',
    campaignSlug,
  ],
};

export const getStoryById = async (
  hardcodedStories: Record<string, Story>,
  options: Parameters<typeof getStory>[0],
) => {
  try {
    const response = await getStory(options);

    if (response?.story) {
      return response.story;
    }
  } catch (e) {
    // nothing to do - just use hardcodedStories
  }

  const formattedId = options.searchParams.id.toLowerCase();
  // NOTE: return null instead of undefined because react-query shows next error
  // "Query data cannot be undefined. Please make sure to return a value other than undefined from your query function."
  return hardcodedStories[formattedId] || null;
};

const storyKeys = {
  story: (storyId: string) => ['getStoryById', { storyId }],
};

export const useStoryQuery = (storyId: string, hardcodedStories: Record<string, Story>) =>
  useQuery({
    queryKey: storyKeys.story(storyId),
    queryFn: () => getStoryById(hardcodedStories, { searchParams: { id: storyId } }),
    enabled: Boolean(storyId),
  });

export const prefetchStoryQuery = async (
  clientQuery: QueryClient,
  hardcodedStories: Record<string, Story>,
  options: Parameters<typeof getStory>[0],
) => {
  const queryKey = storyKeys.story(options.searchParams.id);

  await clientQuery.prefetchQuery<Story>({
    queryKey,
    queryFn: () => getStoryById(hardcodedStories, options),
  });

  return clientQuery.getQueryData<ReturnType<typeof getStoryById>>(queryKey);
};

export const useZealyParticipantListInfiniteQuery = (
  options?: Pick<
    UndefinedInitialDataInfiniteOptions<
      Awaited<ReturnType<typeof getZealyParticipants>>,
      unknown,
      unknown,
      any,
      { nextPage: number }
    >,
    'enabled'
  >,
) => {
  return useInfiniteQuery({
    queryKey: storyQueryKeys.zealyParticipantInfiniteList,
    queryFn: ({ pageParam }) =>
      getZealyParticipants({ searchParams: { page: pageParam?.nextPage || 0 } }),
    initialPageParam: { nextPage: 0 },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.totalPages !== pages.length
        ? {
            nextPage: pages.length,
          }
        : undefined;
    },
    staleTime: 30000,
    refetchInterval: 5 * 60 * 1000, // 5 min
    ...options,
  });
};

export const prefetchZealyParticipantsListInfiniteQuery = (queryClient: QueryClient) => {
  return queryClient.prefetchInfiniteQuery({
    queryKey: storyQueryKeys.zealyParticipantInfiniteList,
    queryFn: () => getZealyParticipants(),
    initialPageParam: 0,
  });
};

export const useZealyQuestsQuery = () => {
  return useQuery({
    queryKey: storyQueryKeys.zealyAllQuests,
    queryFn: () => getZealyCampaign(),
    refetchInterval: 5 * 60 * 1000, // 5 min
  });
};

export const useSelfZealyAccountQuery = (
  options?: Pick<
    UndefinedInitialDataOptions<Awaited<ReturnType<typeof getZealyAccount>>, unknown, unknown, any>,
    'enabled'
  >,
) => {
  return useQuery({
    queryKey: storyQueryKeys.selfZealyAccount,
    queryFn: () => getZealyAccount(),
    staleTime: 0,
    refetchInterval: 5 * 60 * 1000, // 5 min
    ...options,
  });
};

export const useVerifyZealyMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => verifyZealy(),
    onSuccess: (data) => {
      queryClient.setQueryData(storyQueryKeys.selfZealyAccount, (old?: null | ZealyAccount) => {
        if (!old) {
          return {
            account: data,
            quests: {},
          };
        }

        return {
          ...old,
          account: data,
        };
      });
    },
  });
};

export const prefetchZealyQuestsQuery = (queryClient: QueryClient, options?: Options) => {
  return queryClient.prefetchQuery({
    queryKey: storyQueryKeys.zealyAllQuests,
    queryFn: () => getZealyCampaign(options),
  });
};

export const useStormTradeParticipantListInfiniteQuery = (
  options?: Pick<
    UndefinedInitialDataInfiniteOptions<
      Awaited<ReturnType<typeof getStormTradeCampaignParticipants>>,
      unknown,
      unknown,
      any,
      { nextPage: number }
    >,
    'enabled'
  >,
) => {
  return useInfiniteQuery({
    queryKey: storyQueryKeys.stormTradeParticipantInfiniteList,
    queryFn: ({ pageParam }) =>
      getStormTradeCampaignParticipants({ searchParams: { page: pageParam?.nextPage || 0 } }),
    initialPageParam: { nextPage: 0 },
    getNextPageParam: (lastPage, pages) => {
      const pageParam =
        lastPage.data.length === DEFAULT_PARTICIPANT_LIST_LIMIT
          ? {
              nextPage: pages.length,
            }
          : undefined;

      return pageParam;
    },
    staleTime: 30000,
    refetchInterval: 5 * 60 * 1000, // 5 min
    ...options,
  });
};

export const useStormCampaignSelfParticipant = (
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getStormTradeSelfPosition>>,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >,
) => {
  return useQuery({
    queryKey: storyQueryKeys.selfStormTradeParticipant,
    queryFn: () => getStormTradeSelfPosition(),
    ...options,
  });
};

export const useAutoLayerParticipantListInfiniteQuery = (
  options?: Pick<
    UndefinedInitialDataInfiniteOptions<
      Awaited<ReturnType<typeof getAutoLayerCampaignParticipants>>,
      unknown,
      unknown,
      any,
      { nextOffset: number }
    >,
    'enabled'
  >,
) => {
  return useInfiniteQuery({
    queryKey: storyQueryKeys.autoLayerParticipantInfiniteList,
    queryFn: ({ pageParam }) =>
      getAutoLayerCampaignParticipants({ searchParams: { offset: pageParam?.nextOffset || 0 } }),
    initialPageParam: { nextOffset: 0 },
    getNextPageParam: (lastPage, pages) => {
      const pageParam =
        lastPage.length === DEFAULT_PARTICIPANT_LIST_LIMIT
          ? {
              nextOffset: pages.length * DEFAULT_PARTICIPANT_LIST_LIMIT,
            }
          : undefined;

      return pageParam;
    },
    staleTime: 30000,
    refetchInterval: 5 * 60 * 1000, // 5 min
    ...options,
  });
};

export const useSelfAutoLayerParticipant = (
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getSelfAutoLayerCampaignParticipant>>,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >,
) => {
  return useQuery({
    queryKey: storyQueryKeys.selfAutoLayerParticipant,
    queryFn: () => getSelfAutoLayerCampaignParticipant(),
    ...options,
  });
};

export const useCampaignLeaderboardInfiniteQuery = (
  campaignSlug: string,
  options?: Pick<
    UndefinedInitialDataInfiniteOptions<
      Awaited<ReturnType<typeof getCampaignLeaderboard>>,
      unknown,
      unknown,
      any,
      { nextOffset: number }
    >,
    'enabled'
  >,
) => {
  return useInfiniteQuery({
    queryKey: storyQueryKeys.campaignLeaderboard(campaignSlug),
    queryFn: ({ pageParam }) =>
      getCampaignLeaderboard(campaignSlug, {
        searchParams: { offset: pageParam?.nextOffset || 0 },
      }),
    initialPageParam: { nextOffset: 0 },
    getNextPageParam: (lastPage, pages) => {
      const totalItems = pages.reduce((acc, page) => acc + page.length, 0);
      const nextOffset = Math.floor(totalItems / CAMPAIGN_LEADERBOARD_DEFAULT_PAGE_LIMIT);
      const hasNextPage = lastPage.length === CAMPAIGN_LEADERBOARD_DEFAULT_PAGE_LIMIT;

      const pageParam = hasNextPage
        ? {
            nextOffset,
          }
        : undefined;

      return pageParam;
    },
    staleTime: 30000,
    refetchInterval: 5 * 60 * 1000, // 5 min
    ...options,
  });
};

export const useSelfCampaignLeaderboardMe = (
  campaignSlug: string,
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getSelfCampaignLeaderboardMe>>,
      unknown,
      unknown,
      any
    >,
    'enabled'
  >,
) => {
  return useQuery({
    queryFn: () => getSelfCampaignLeaderboardMe(campaignSlug),
    queryKey: storyQueryKeys.selfCampaignLeaderboardMe(campaignSlug),
    ...options,
  });
};
