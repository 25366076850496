import { NormalizedFeatureToggles } from '@shared/api/types/featureToggles';
import {
  useClientFeatureTogglesQuery,
  useFeatureTogglesQuery,
} from '@shared/hooks/query/featureToggles';

const defaultFeatureToggles: NormalizedFeatureToggles = {};

export const useFeatureToggles = () => {
  const { data = defaultFeatureToggles } = useFeatureTogglesQuery();

  return data;
};

export const useClientFeatureToggles = () => {
  const { data = defaultFeatureToggles } = useClientFeatureTogglesQuery();

  return data;
};
