'use client';

// To test localhost in TMA do next:
//  1. Change hostname to '127.0.0.1' in launchpad project.json file
//  2. Run 'sh scripts/launchpad-dev-mobile.sh https tma' in terminal
//  3. After running this command you will get local URL.
//    e.g. 'Network: https://127.0.0.1:4250'.
//    Put this URL into the TMA Bot Settings
import { useEffect, useState } from 'react';

import { WebApp as WebAppInterface } from '@twa-dev/types';

declare global {
  interface Window {
    Telegram?: {
      WebApp: WebAppInterface;
    };
  }
}

const useTelegramMiniAppSdk = () => {
  const [webApp, setWebApp] = useState<undefined | WebAppInterface>(undefined);
  const [hasTimedOut, setHasTimedOut] = useState(false);

  // All this hacking login needed due to @twa-dev/sdk doesn't work properly with Next.js
  // and throws an error when importing that window is not defined
  useEffect(() => {
    const timeoutLimit = 10000; // 10 seconds
    const pollingInterval = 1000; // 1 seconds

    const checkForWebApp = () => {
      const WebApp = window?.Telegram?.WebApp;

      if (WebApp) {
        WebApp.ready();
        setWebApp(WebApp);
        return true;
      }

      return false;
    };

    // Immediate check
    if (checkForWebApp()) {
      return;
    }

    // eslint-disable-next-line prefer-const
    let intervalId: NodeJS.Timeout;

    const timeoutId = setTimeout(() => {
      setHasTimedOut(true);
      clearInterval(intervalId);
    }, timeoutLimit);

    intervalId = setInterval(() => {
      if (checkForWebApp()) {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      }
    }, pollingInterval);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, []);

  return {
    webApp,
    isAppOpenedInTelegram: Boolean(webApp?.initData),
    hasTimedOut,
  };
};

export default useTelegramMiniAppSdk;
